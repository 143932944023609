import React from 'react'
import { css } from '@emotion/core'
import { Fade } from 'react-reveal'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '@dqp/common/components/Image'
import Link from '@dqp/common/components/Link/Link'
import LoginForm from './LoginForm'
import routes from '#globals/routes'
import {
  reactRevealDistance,
  reactRevealDuration,
} from '#globals/constants'

const styles = {
  contaner: css`
    background-color: ${colors.purple};
    ${breakpoints({
      paddingTop: [40, 40, 100, 120, 120],
      paddingBottom: [80, 80, 100, 120, 120],
    })};
  `,
  title: css`
    ${spacer.mrB20};
  `,
  text: css`
    line-height: 1.5;
    ${spacer.mrB30};
  `,
  books: css`
    ${breakpoints({
      top: [0, 0, 0, -310, -310],
      right: [0, 0, 0, -70, -70],
    })};
  `,
}
function Hero() {
  return (
    <section css={styles.contaner}>
      <div className='container'>
        <div className='row align-items-center'>
          <Fade
            left
            distance={reactRevealDistance}
            duration={reactRevealDuration}
          >
            <div className='col-lg-5 d-lg-flex d-none justify-content-center'>
              <Image
                css={styles.books}
                className='d-none d-lg-block'
                alt='books '
                image='login/books'
                sizes={{
                  lg: {
                    width: 843,
                  },
                  md: {},
                }}
              />
            </div>
          </Fade>
          <div className='col-lg-1' />
          <div className='col-lg-6'>
            <Title
              as='h1'
              size='xxxxLarge'
              weight='bold'
              color={colors.white}
              css={styles.title}
            >
              Welcome back!
            </Title>
            <Text size='large' color={colors.white} css={styles.text}>
              Need a Daily Quest account?{' '}
              <strong>
                <Link
                  to={routes.signup.to}
                  hoverColor={colors.greyLight}
                  borderColor={colors.white}
                >
                  Sign up
                </Link>
              </strong>
            </Text>
            <LoginForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
