import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text, { ErrorText } from '@dqp/common/components/Text/Text'
import Link from '@dqp/common/components/Link/Link'
import Checkbox from '@dqp/common/components/Checkbox/Checkbox'
import TextField from '@dqp/common/components/TextField/TextField'
import PasswordField from '@dqp/common/components/PasswordField/PasswordField'
import { get } from 'lodash-es'
import Cookies from 'js-cookie'
import routes from '#globals/routes'
import { useLogin } from '#api/auth'
import Stack from '#components/Stack/Stack'
import { storageKeys } from '#globals/constants'

const styles = {
  text: css`
    line-height: 1.5;
  `,
  checkbox: css`
    ${spacer.mrR10};
    border-color: ${colors.white};
    fill: ${colors.purple};
    background-color: white;
  `,
}
const LoginSchema = object().shape({
  email: string()
    .email('Invalid email.')
    .required('Email is required.'),
  password: string().required('Password is required.'),
})

function LoginForm() {
  const { login } = useLogin()
  const [errorMessage, setErrorMessage] = useState('')
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    values,
    errors,
    isValid,
    touched,
  } = useFormik({
    validateOnBlur: true,
    validationSchema: LoginSchema,
    initialValues: {
      email: '',
      password: '',
      keepUserLoggedIn: true,
    },

    onSubmit: async (formData, actions) => {
      try {
        await login({ variables: formData })
      } catch (error) {
        const reason = get(
          error,
          'graphQLErrors[0].extensions.reason',
        )
        if (reason) {
          setErrorMessage(reason)
          // to keep button disabled unless interacted again
          actions.setErrors({ email: '', password: '' })
        }
      }
    },
  })
  useEffect(() => {
    Cookies.set(storageKeys.keepLoggedIn, values.keepUserLoggedIn, {
      expires: 365,
    })
  }, [values.keepUserLoggedIn])

  useEffect(() => {
    setErrorMessage('')
  }, [values])
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={20}>
        <div>
          <TextField
            name='email'
            label='Email'
            labelProps={{ color: colors.white }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && errors.email}
          />
        </div>
        <div>
          <PasswordField
            name='password'
            label='Password'
            labelProps={{ color: colors.white }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
          />
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <Text
            as='label'
            color={colors.white}
            size='small'
            className='d-flex align-items-center mr-4'
            css={styles.text}
          >
            <Checkbox
              name='keepUserLoggedIn'
              onChange={handleChange}
              checked={values.keepUserLoggedIn}
              css={styles.checkbox}
            />
            <span>Keep me logged in</span>
          </Text>

          <Text
            size='small'
            color={colors.white}
            weight='bold'
            css={styles.text}
          >
            <Link
              to={routes.resetpassword.to}
              hoverColor={colors.greyLight}
              borderColor={colors.white}
            >
              <span>Forgotten Password?</span>
            </Link>
          </Text>
        </div>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <Button
          size='medium'
          variant='black'
          disabled={!isValid || isSubmitting}
          type='submit'
          isLoading={isSubmitting}
          css={spacer.mrT30}
        >
          LOG IN
        </Button>
      </Stack>
    </form>
  )
}

export default LoginForm
